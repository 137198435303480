var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":`${_vm.template.name}`,"sub-heading":`For ${_vm.template.customer.name} at ${_vm.template.location.name}`}})],1),_c('column',{staticClass:"is-narrow"},[_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                    name: 'work-order-template-manager',
                    params: {
                        template: _vm.template.id
                    }
                }}},[_vm._v("Overview")]),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                    name: 'work-order-template-schedules',
                    params: {
                        template: _vm.template.id
                    }
                }}},[_vm._v("Schedule")]),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                    name: 'work-order-template-work-orders',
                    params: {
                        template: _vm.template.id
                    }
                }}},[_vm._v("Work Orders")]),_c('router-link',{staticClass:"button is-rounded",attrs:{"to":{
                    name: 'edit-work-order-template',
                    params: {
                        template: _vm.template.id
                    }
                }}},[_vm._v("Edit")])],1)])],1),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }