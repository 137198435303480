<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <page-heading :heading="`${template.name}`"
                :sub-heading="`For ${template.customer.name} at ${template.location.name}`" />
        </column>
        <column class="is-narrow">
            <div class="buttons has-addons is-rounded">
                <router-link :to="{
                    name: 'work-order-template-manager',
                    params: {
                        template: template.id
                    }
                }" class="button is-rounded">Overview</router-link>
                <router-link :to="{
                    name: 'work-order-template-schedules',
                    params: {
                        template: template.id
                    }
                }" class="button is-rounded">Schedule</router-link>
                <router-link :to="{
                    name: 'work-order-template-work-orders',
                    params: {
                        template: template.id
                    }
                }" class="button is-rounded">Work Orders</router-link>
                <router-link :to="{
                    name: 'edit-work-order-template',
                    params: {
                        template: template.id
                    }
                }" class="button is-rounded">Edit</router-link>
            </div>
        </column>
    </columns>
    <router-view />
</loader>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('workOrderTemplate/loadTemplate', this.$route.params.template)
        this.loading = false
    },

    computed: {
        ...mapGetters('workOrderTemplate', [
            'template'
        ])
    }

}
</script>